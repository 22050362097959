import AboutPage from './pages/about';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import { RootAuthPermissions } from 'types';

interface AboutAppProps {
  permissions: RootAuthPermissions;
  themeService: unknown;
}


const RootApp = (props: AboutAppProps) => {
  return <App componentToRender={
    <AboutPage tilePermissions={props.permissions.homeAuthorization} />
  }
    route="/about"
    themeService={null}
    permissions={props.permissions.homeAuthorization}
  />;
};

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootApp,
  errorBoundary() {
    return <div>This renders when a catastrophic error occurs</div>;
  },
});

export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
