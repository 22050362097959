import logo from './about-logo.png';
import clockIcon from './clock-icon.svg';
import trendIcon from './trend-icon.svg';
import waveIcon from './wave-icon.svg';
import swayImage from './sway-background-resized.png';

const imageMap: { [key: string]: string } = {
  logo: logo,
  clockIcon: clockIcon,
  trendIcon: trendIcon,
  waveIcon: waveIcon,
  swayImage: swayImage,
};

export default imageMap;
