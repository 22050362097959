import React, {
    Suspense,
    useState,
    useLayoutEffect,
    useRef,
    useEffect,
} from 'react';
import { MetricCard } from '@gitlab-rtsensing/component-library';
import { Carousel, EditIcon, Segmented, Select, Table, Button, LinkExternalIcon, ArrowRightIcon } from '@opsdti-global-component-library/amgen-design-system';

import { AuthPermissions } from 'types';
import { default as valueCaptureConfigData } from './data.json';
import ValueCaptureUpdateModal from 'components/elements/value-capture-update-modal';
import './index.scss';
import { getValueCaptureData, getKeyHighlights, getKeyHighlightsYear } from 'api/value-capture-page';
import { useOktaAuth } from '@okta/okta-react';
import { Value_Capture_Slide_Table_Header, Productivity_Table_Header, KeyHighlight_Table_Header } from './valueCaptureTable';
import { useValueCaptureNavManager } from 'hooks/use-nav-manager';
import { List } from 'antd';
import KeyHighlightUpdateModal from 'components/elements/value-capture-update-modal/keyhighlight';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface ValueCaptureProps {
    tilePermissions: AuthPermissions;
}

export const ValueCapture = (props: ValueCaptureProps): JSX.Element => {
    useValueCaptureNavManager();
    const [mode, setMode] = useState("View")
    const [valueCaptureSlideUploadModalOpen, setValueCaptureSlideUploadModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [valueCaptureData, setValueCaptureData] = useState<[]>([]);
    const [keyHighlightsData, setKeyHighlightsData] = useState<any>([]);
    const [keyHighlightsYear, setKeyHighlightsYear] = useState<any>([]);
    const [keyHighlightsQuarter, setKeyHighlightQuarter] = useState<string[]>([]);
    const [keyHighlightSelectedYear, setKeyHighlightSelectedYear] = useState('');
    const [keyHighlightSelectedquarter, setKeyHighlightSelectedquarter] = useState('');

    const [keyQuotesYear, setKeyQuotesYear] = useState<any>([]);
    const [keyQuotesQuarter, setKeyQuotesQuarter] = useState<string[]>([]);
    const [keyQuotesSelectedYear, setKeyQuotesSelectedYear] = useState('');
    const [keyQuotesSelectedquarter, setKeyQuotesSelectedQuarter] = useState('');

    const [modalTitle, setModalTitle] = useState('');


    const [pageSection, setPageSection] = useState("");
    const [tableHeader, setTableHeader] = useState<{ Header: string; accessor: string; }[]>([]);
    const { authState } = useOktaAuth();
    const keyimpactmetricsRef = useRef<HTMLDivElement>(null);
    const keyquotesRef = useRef<HTMLDivElement>(null);
    const keyhighlightsRef = useRef<HTMLDivElement>(null);
    const productivityAndsavingsRef = useRef<HTMLDivElement>(null);
    const valueCaptureContainerRef = useRef<HTMLDivElement>(null);
    const [slidesToShow, setSlidesToShow] = useState(0);
    const [keyHighlighUpdateModalOpen, setKeyHighlighUpdateModalOpen] = useState(false);
    const [productivityTblYear, setProductivityTblYear] = useState([]);
    const [aiFlag, setAIFlag] = useState("NL");
    const { tilePermissions } = props;
    const [keyAINotesData, setKeyAINotesData] = useState<any>([]);

    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {
        keyimpactmetrics: keyimpactmetricsRef,
        keyhighlights: keyhighlightsRef,
        productivityAndsavings: productivityAndsavingsRef,
        keyquotes: keyquotesRef,
        valueCaptureContainer: valueCaptureContainerRef,
    };

    const getRef = (key: string): React.RefObject<HTMLDivElement> | null => {
        return refs[key] || null;
    };
    const navigate = useNavigate();

    useEffect(() => {
        get_ValueCaptureData();
        get_KeyHighlightsYear();
    }, []);

    useEffect(() => {
        get_KeyHighlights();
    }, [keyHighlightSelectedYear]);

    useEffect(() => {
        get_KeyAINotes();
    }, [keyQuotesSelectedYear]);


    useLayoutEffect(() => {

        const resizeObserver = new ResizeObserver((entries) => {
            const width = entries[0].contentRect.width;
            const itemLength = valueCaptureData.filter((content: any) => content.page_section === 'carousel').length;

            if (itemLength === 0) return;

            if (width > 1700) {
                //When side bar is collapsed state
                setSlidesToShow(itemLength > 6 ? 6 : itemLength);
            } else if (width > 1200) {
                //When side bar is expanded state
                setSlidesToShow(itemLength > 5 ? 5 : itemLength);
            } else if (width > 700) {
                //When in tablet
                setSlidesToShow(itemLength > 3 ? 3 : itemLength);
            } else {
                //When in mobile
                setSlidesToShow(1);
            }
        })

        if (valueCaptureContainerRef.current) {
            resizeObserver.observe(valueCaptureContainerRef.current);
        }

        return () => {
            if (valueCaptureContainerRef.current) {
                resizeObserver.unobserve(valueCaptureContainerRef.current);
            }
        }
    });

    const getQtrAndyear = (year: string, sectionName: string) => {
        const dt = new Date();
        const month = dt.getMonth() + 1;
        let returnVal: string[] = [];
        const currentYr = dt.getFullYear();

        if (currentYr !== parseInt(year)) {
            returnVal = ['Q1', 'Q2', 'Q3', 'Q4'];
            if (month <= 3 && sectionName === 'keyHighlights') {
                setKeyHighlightSelectedYear((parseInt(year) - 1).toString());
            } else if (month <= 3 && sectionName === 'keyQuotes') {
                setKeyQuotesSelectedYear((parseInt(year) - 1).toString());
            } else if (month > 3 && sectionName === 'keyHighlights') {
                setKeyHighlightSelectedYear(year);
            } else if (month > 3 && sectionName === 'keyQuotes') {
                setKeyQuotesSelectedYear(year);
            }
            return returnVal
        }
        //Below logic is to get the quarter based on the month, it opens up once that current quarter is over, since news letters are updated after that quarter is passed
        switch (true) {
            case month <= 3:
                //before april
                returnVal = ['Q1'];
                break;
            case month <= 6:
                //before july
                returnVal = ['Q1', 'Q2'];
                break;
            case month <= 9:
                //before october,
                returnVal = ['Q1', 'Q2', 'Q3'];
                break;
            case month <= 12:
                //before december,
                returnVal = ['Q1', 'Q2', 'Q3', 'Q4'];
                break;
        }

        // Settting returnVal.length - 2, since previous quarter newsletter is out in current quarter -1 + -1 
        if (returnVal.length >= 2) {
            if (sectionName === 'keyHighlights') {
                setKeyHighlightSelectedYear(year);
                setKeyHighlightSelectedquarter(returnVal[returnVal.length - 2]);
            } else {
                setKeyQuotesSelectedYear(year);
                setKeyQuotesSelectedQuarter(returnVal[returnVal.length - 2]);
            }
        } else {
            if (sectionName === 'keyHighlights') {
                setKeyHighlightSelectedquarter('Q4');
                setKeyHighlightSelectedYear((parseInt(year) - 1).toString());
            } else {
                setKeyQuotesSelectedQuarter('Q4');
                setKeyQuotesSelectedYear((parseInt(year) - 1).toString());
            }
        }
        return returnVal;
    };

    const get_ValueCaptureData = async () => {
        try {
            setLoading(true);
            const res = await getValueCaptureData(authState?.accessToken?.accessToken);

            if (res?.data?.data?.length === 0) {
                res.data.data = [
                    {
                        "id": -1,
                        "page_section": "productivityTable",
                        "metric_id": "",
                        "metric_value": "",
                        "delivery_date": "",
                        "index": -1,
                        "quarter": "",
                        "year": ""
                    },
                    {
                        "id": -3,
                        "page_section": "keyQuotes",
                        "metric_id": "",
                        "metric_value": "",
                        "delivery_date": "None",
                        "index": -1,
                        "quarter": "None",
                        "year": "None"
                    }
                ]
                setValueCaptureSlideUploadModalOpen(false)
            }

            if (res?.data?.data?.filter((content: any) => content.page_section === 'carousel').length === 0) {
                //since carousel is not added above ===0 block, using it here. doesn't required empty carousel section
                setValueCaptureSlideUploadModalOpen(false)
            }
            if (res?.data?.data?.length > 0) {

                const dataArray = res.data.data.map((content: any) => {
                    content.yearQuarter = content.quarter + ' ' + content.year;
                    return content;
                });
                setValueCaptureData(dataArray);
                /* const unique_year = dataArray.filter((page: any) => page.page_section === 'productivityTable')
                    .map((item: any) => item.year)
                    .filter(
                        (value: any, index: number, current_value: any) => current_value.indexOf(value) === index
                    );
                
                setProductivityTblYear(unique_year); */
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };

    const get_KeyHighlights = async () => {
        try {
            setLoading(true);
            const res = await getKeyHighlights(authState?.accessToken?.accessToken, "NL", parseInt(keyHighlightSelectedYear));
            if (res?.data?.data?.content.length === 0) {
                setKeyHighlighUpdateModalOpen(false)
            }

            if (res?.data?.data?.content.length > 0) {
                const keyhighlights = res.data.data.content[0].newsLetters
                setKeyHighlightsData(keyhighlights);
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };

    const get_KeyHighlightsYear = async () => {
        try {
            setLoading(true);
            const res = await getKeyHighlightsYear(authState?.accessToken?.accessToken);
            if (res?.data?.data?.length > 0) {
                const yearList = res.data.data
                setKeyHighlightsYear(yearList);
                setKeyHighlightQuarter(getQtrAndyear(yearList[0], 'keyHighlights'));

                setKeyQuotesYear(yearList);
                setKeyQuotesQuarter(getQtrAndyear(yearList[0], 'keyQuotes'));
                setProductivityTblYear(yearList);
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };

    const get_KeyAINotes = async () => {
        try {
            setLoading(true);
            const res = await getKeyHighlights(authState?.accessToken?.accessToken, "AI", parseInt(keyQuotesSelectedYear));
            if (res?.data?.data?.content.length > 0) {
                const keyAI = res.data.data.content[0].newsLetters
                setKeyAINotesData(keyAI);
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };
    return (
        <>
            {tilePermissions?.aboutValue &&
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="px-0">
                        <div className={`collapse navbar-collapse show `} id="navbarNav">
                            <ul className="navbar-nav border-bottom about-section-name">
                                <li key={'about'} className="nav-item">
                                    <Link className='nav-link px-0' to={'/about'}>
                                        <span className="menu-text">{'About Sensing'}</span>
                                    </Link>
                                </li>

                                <li key={'value-capture'} className="nav-item">
                                    <Link className='nav-link active px-0' to={'/about/value-capture'}
                                    >
                                        <span className="menu-text">{'Value Capture'}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            }
            <div className='value-capture-container' ref={valueCaptureContainerRef}>
                {(tilePermissions?.aboutEdit) &&
                    <div className='value-capture-btn'>
                        <Segmented
                            options={['View', 'Edit']}
                            onChange={(value: any) => {
                                setMode(value);
                            }}
                        />
                    </div>
                }
                <div className='value-capture-heading'></div>
                <div className="">
                    {mode === 'Edit' ?
                        <Button type='secondary' text='EDIT'
                            icon={<EditIcon color="primary" height={14} width={14} />}
                            className='edit-icon-style'
                            onClick={() => { setValueCaptureSlideUploadModalOpen(true); setPageSection('carousel'); setTableHeader(Value_Capture_Slide_Table_Header); setModalTitle('Sensing Key Impact Metric') }}
                        /> :
                        <></>
                    }
                    {valueCaptureData.length > 0 && valueCaptureConfigData.tiles.map((tile: any, index: number) => {
                        if (tile.key === 'keyimpactmetrics') {
                            return (
                                <>
                                    <div className='value-capture-subheading'> {tile.title}</div>
                                    <div
                                        id={tile.key}
                                        key={tile.key + index}
                                        ref={getRef(tile.key)}
                                        className='carousel-container'
                                    >
                                        {valueCaptureData.filter((content: any) => content.page_section === 'carousel').length > 0 ?
                                            <Carousel
                                                className='value-capture-carousel'
                                                key={tile.key}
                                                items={valueCaptureData.sort((a: { index: number }, b: { index: number }) => a?.index - b?.index).filter((content: any) => content.page_section === 'carousel').map((vc_content: any) => {
                                                    return (
                                                        <div key={vc_content.id} className='value-capture-carousel-item'>
                                                            <div className='value-capture-number'>{vc_content.metric_value}</div>
                                                            <div className='value-capture-title'>{vc_content.metric_id}</div>
                                                        </div>
                                                    )
                                                })}
                                                outline
                                                slidesToShow={slidesToShow}
                                            /> : <div className='no-data-carousel'>Data not available</div>}
                                    </div>
                                </>
                            );
                        }
                        else if (tile.key === 'successStories') {
                            return (
                                <>
                                    <div className='value-capture-subheading'> {tile.title}</div>
                                    <div key={tile.key} className='success-container'>
                                        {tile.content.map((tile: any, index: number) => {
                                            if (tile.key === 'keyhighlights') {
                                                return (
                                                    <div
                                                        id={tile.key}
                                                        key={tile.key + index}
                                                        ref={getRef(tile.key)}
                                                        className={`value-capture-success value-capture-grid-item `}
                                                    >
                                                        <MetricCard className="ops-metric-card value-capture-card">
                                                            <>
                                                                <MetricCard.Header className="tile-headers">
                                                                    <MetricCard.Title title={tile.title} />
                                                                    {mode === 'Edit' ?
                                                                        <Button type='secondary' text='EDIT'
                                                                            icon={<EditIcon color="primary" height={14} width={14} />}
                                                                            className='edit-icon-style'
                                                                            onClick={() => { setKeyHighlighUpdateModalOpen(true); setAIFlag("NL"); setModalTitle('Key Highlights') }}
                                                                        /> :
                                                                        <></>
                                                                    }
                                                                </MetricCard.Header>
                                                                <MetricCard.Content>
                                                                    <div className='key-container'>
                                                                        <div className='value-capture-dropdown'>
                                                                            <div className='dropdown-container'>
                                                                                <label className='value-capture-label'>YEAR: </label>
                                                                                <Select className='value-capture-select' value={keyHighlightSelectedYear} onChange={(e) => {
                                                                                    setKeyHighlightSelectedYear(e);
                                                                                    setKeyHighlightQuarter(getQtrAndyear(e, 'keyHighlights'));
                                                                                }}>
                                                                                    {keyHighlightsYear.map((year: any, index: number) =>
                                                                                        <Select.Option key={index} value={year}>{year}</Select.Option>
                                                                                    )}
                                                                                </Select>
                                                                            </div>
                                                                            <div className='dropdown-container'>
                                                                                <label className='value-capture-label'>QUARTER:</label>
                                                                                <Select className='value-capture-select' value={keyHighlightSelectedquarter} onChange={(e) => setKeyHighlightSelectedquarter(e)}>
                                                                                    <>{keyHighlightsQuarter.map((quarter: any, index) =>
                                                                                        <Select.Option key={index} value={quarter}>{quarter}</Select.Option>
                                                                                    )}</>
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                        <div className='value-capture-highlight'>
                                                                            <ol className='value-capture-bullet-list'>
                                                                                {keyHighlightsData.sort((a: { index: number }, b: { index: number }) => a?.index - b?.index).filter((content: any) => content.year === keyHighlightSelectedYear && content.quarter === keyHighlightSelectedquarter && content.ai_flag === "NL").map((list: any) =>
                                                                                    <li className='' key={list.highlight}>{list.highlight}</li>
                                                                                )}
                                                                            </ol>
                                                                        </div>
                                                                        <div className='value-capture-highlight-footer'>
                                                                            <Button
                                                                                block
                                                                                text="go to newsletter"
                                                                                type='secondary'
                                                                                icon={<LinkExternalIcon height="100%" width={16} />}
                                                                                href={keyHighlightsData.filter((content: any) => content.year === keyHighlightSelectedYear && content.quarter === keyHighlightSelectedquarter)?.[0]?.link?.toString()}
                                                                                target="_blank"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </MetricCard.Content>
                                                            </>
                                                        </MetricCard>
                                                    </div>
                                                )
                                            }
                                            if (tile.key === 'keyquotes') {
                                                return (
                                                    <div
                                                        id={tile.key}
                                                        key={tile.key + index}
                                                        ref={getRef(tile.key)}
                                                        className={`value-capture-success value-capture-grid-item `}
                                                    >
                                                        <MetricCard className="ops-metric-card value-capture-card">
                                                            <>
                                                                <MetricCard.Header className="tile-headers">
                                                                    <MetricCard.Title title={tile.title} />
                                                                    {mode === 'Edit' ?
                                                                        <Button type='secondary' text='EDIT'
                                                                            icon={<EditIcon color="primary" height={14} width={14} />}
                                                                            className='edit-icon-style'
                                                                            onClick={() => { setKeyHighlighUpdateModalOpen(true); setAIFlag("AI"); setModalTitle('Key AI Release Notes') }}
                                                                        /> :
                                                                        <></>
                                                                    }
                                                                </MetricCard.Header>
                                                                <MetricCard.Content>
                                                                    <div className='key-container'>
                                                                        <div className='value-capture-dropdown'>
                                                                            <div className='dropdown-container'>
                                                                                <label className='value-capture-label'>YEAR: </label>
                                                                                <Select className='value-capture-select' value={keyQuotesSelectedYear} onChange={(e) => {
                                                                                    setKeyQuotesSelectedYear(e);
                                                                                    setKeyQuotesQuarter(getQtrAndyear(e, 'keyQuotes'));
                                                                                }}>
                                                                                    {keyQuotesYear.map((year: any, index: number) =>
                                                                                        <Select.Option key={index} value={year}>{year}</Select.Option>
                                                                                    )}
                                                                                </Select>
                                                                            </div>
                                                                            <div className='dropdown-container'>
                                                                                <label className='value-capture-label'>QUARTER:</label>
                                                                                <Select className='value-capture-select' value={keyQuotesSelectedquarter} onChange={(e) => setKeyQuotesSelectedQuarter(e)} >
                                                                                    <>{keyQuotesQuarter.map((quarter: any, index) =>
                                                                                        <Select.Option key={index} value={quarter}>{quarter}</Select.Option>
                                                                                    )}</>
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                        <div className='value-capture-highlight'>
                                                                            <ol className='value-capture-bullet-list'>
                                                                                {keyAINotesData.sort((a: { index: number }, b: { index: number }) => a?.index - b?.index).filter((content: any) => content.year === keyQuotesSelectedYear && content.quarter === keyQuotesSelectedquarter && content.ai_flag === "AI").map((list: any) =>
                                                                                    <li className='' key={list.highlight}>{list.highlight}</li>
                                                                                )}
                                                                            </ol>
                                                                        </div>
                                                                        <div className='value-capture-highlight-footer'>
                                                                            <Button
                                                                                block
                                                                                text="go to release notes"
                                                                                type='secondary'
                                                                                icon={<ArrowRightIcon color='primary' height={18} width={18} />}
                                                                                onClick={() => {
                                                                                    navigate('/about/notes');
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </MetricCard.Content>
                                                            </>
                                                        </MetricCard>
                                                        {/* <List
                                                        className="value-capture-list"
                                                        header={<div className='edit-btn-container'>Key Quotes
                                                            {mode === 'Edit' ? <Button type='secondary' text='EDIT'
                                                                icon={<EditIcon color="primary" height={14} width={14} />}
                                                                className='edit-icon-style' onClick={() => { setValueCaptureSlideUploadModalOpen(true); setPageSection('keyQuotes'); setTableHeader(KeyQoute_Table_Header); setModalTitle('Key Quotes') }}
                                                            /> : <></>}
                                                        </div>}
                                                        bordered={false}
                                                        dataSource={valueCaptureData.filter((content: any) => content.page_section === "keyQuotes")}
                                                        renderItem={(item: any) => (
                                                            <List.Item>
                                                                <div className=''>
                                                                    {item.metric_id}
                                                                </div>
                                                                <text className='author-style'>
                                                                    {item.metric_value}
                                                                </text>
                                                            </List.Item>
                                                        )}
                                                        pagination={{
                                                            pageSize: 1,
                                                            position: 'bottom',
                                                            align: 'end',
                                                        }}
                                                    /> */}
                                                    </div>
                                                )
                                            }

                                        })}
                                    </div>
                                </>
                            )
                        }
                        else {
                            return (
                                <>
                                    <div className='value-capture-subheading'> {tile.title}</div>
                                    <div
                                        id={tile.key}
                                        key={tile.key + index}
                                        ref={getRef(tile.key)}
                                        className={`value-capture-grid-item`}
                                    >
                                        <Suspense key={tile.key} fallback={<div>Loading...</div>}>
                                            <MetricCard className="ops-metric-card value-capture-table">
                                                <>
                                                    <MetricCard.Header className="tile-headers">
                                                        <MetricCard.Title title={tile.title} />
                                                        {mode === 'Edit' ? <Button type='secondary' text='EDIT'
                                                            icon={<EditIcon color="primary" height={14} width={14} />}
                                                            className='edit-icon-style' onClick={() => { setValueCaptureSlideUploadModalOpen(true); setPageSection('productivityTable'); setTableHeader(Productivity_Table_Header); setModalTitle(tile.title) }}
                                                        /> : <></>}
                                                    </MetricCard.Header>
                                                    <MetricCard.Content>
                                                        <Table
                                                            columns={[
                                                                {
                                                                    dataIndex: 'metric_value',
                                                                    key: 'metric_value',
                                                                    title: 'Measure/ Outcome'
                                                                },
                                                                {
                                                                    dataIndex: 'metric_id',
                                                                    key: 'metric_id',
                                                                    title: 'Key Driver'
                                                                },
                                                                {
                                                                    dataIndex: 'yearQuarter',
                                                                    key: 'yearQuarter',
                                                                    title: 'Year Quarter'
                                                                }
                                                            ]}
                                                            dataSource={valueCaptureData.sort((a: { index: number }, b: { index: number }) => a?.index - b?.index).filter((content: any) => content.page_section === "productivityTable")}
                                                            style={{
                                                                width: '100%',
                                                                maxHeight: '285px',
                                                                overflow: 'scroll'
                                                            }}
                                                        />
                                                    </MetricCard.Content>
                                                </>
                                            </MetricCard>
                                        </Suspense>

                                    </div>
                                </>
                            );
                        }
                    })}

                    {valueCaptureSlideUploadModalOpen ? <ValueCaptureUpdateModal
                        opened={valueCaptureSlideUploadModalOpen}
                        valueCaptureUpdateModalClosed={() => setValueCaptureSlideUploadModalOpen(false)}
                        valueCaptureData={valueCaptureData}
                        refreshTable={get_ValueCaptureData}
                        pagesection={pageSection}
                        tableHeader={tableHeader}
                        title={modalTitle}
                        yearList={productivityTblYear}
                    /> : <></>}

                    {keyHighlighUpdateModalOpen ? <KeyHighlightUpdateModal
                        opened={keyHighlighUpdateModalOpen}
                        KeyHighlightUpdateModalClosed={() => setKeyHighlighUpdateModalOpen(false)}
                        KeyHighlightData={aiFlag === "AI" ? keyAINotesData : keyHighlightsData}
                        refreshTable={aiFlag === "AI" ? get_KeyAINotes : get_KeyHighlights}
                        tableHeader={KeyHighlight_Table_Header}
                        year={aiFlag === "NL" ? keyHighlightSelectedYear : keyQuotesSelectedYear}
                        quarter={aiFlag === "NL" ? keyHighlightSelectedquarter : keyQuotesSelectedquarter}
                        yearList={keyHighlightsYear}
                        AIFlag={aiFlag}
                        title={modalTitle}
                    /> : <></>}
                </div >
            </div>
        </>
    );
};

export default ValueCapture;
