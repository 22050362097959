import { Modal, Loader } from '@gitlab-rtsensing/component-library';
import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import { useOktaAuth } from '@okta/okta-react';
import { Button, PlusIcon, TrashIcon, TextArea } from '@opsdti-global-component-library/amgen-design-system';
import { deleteKeyHighlight, updateKeyHighlight, getKeyHighlights } from 'api/value-capture-page';
import { Select } from '@opsdti-global-component-library/amgen-design-system';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface KeyHighlightProps {
    opened: boolean;
    KeyHighlightUpdateModalClosed: () => void;
    KeyHighlightData: [];
    refreshTable: () => void;
    tableHeader: { Header: string, accessor: string }[];
    year: string;
    quarter: string;
    yearList: string[];
    AIFlag: string;
    title: string;
}

const KeyHighlightUpdateModal = (props: KeyHighlightProps) => {
    const { opened, KeyHighlightUpdateModalClosed, KeyHighlightData, refreshTable, tableHeader, year, quarter, yearList, AIFlag, title } = props;
    const { authState } = useOktaAuth();
    const [selectedYear, setSelectedYear] = useState(year);
    const [selectedquarter, setSelectedquarter] = useState(quarter)
    const [keyHighlightData, setKeyHighlightData] = useState<any>(KeyHighlightData);
    const [keyHighlightDataOriginal, setKeyHighlightDataOriginal] = useState<any>(KeyHighlightData);
    const [isLoading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [addRowCount, setAddRowCount] = useState(0);
    const [newsletter, setNewsletter] = useState('');
    const [newsletterDisable, setNewsletterDisable] = useState(true);
    const [quarterList, setQuarterList] = useState<string[]>([]);
    const initialRender = useRef(true);


    const update_DescriptionEvent = (newRow: any, value: string) => {
        /* if (newRow.id.toString().startsWith('NewRow') && newsletter !== '') {
            setNewsletterDisable(false);
        } else {
            setNewsletterDisable(true);
        } */
        const updatedData = keyHighlightData.map((row: any) => {
            if (row.id === newRow.id) {
                return {
                    "id": row.id,
                    "quarter": selectedquarter,
                    "year": selectedYear,
                    "highlight": value,
                    "link": newsletter,
                    "description": row.description,
                    "title": row.title,
                    "ai_flag": row.ai_flag,
                    "index": row.index
                }
            }
            else {
                return row;
            }
        })
        setKeyHighlightData([...updatedData])
    }

    const addNew_keyHighlight = () => {
        const updatedData = keyHighlightData
        updatedData.unshift(
            {
                "id": 0 - addRowCount, //Will convert all negtive row to 0 for new row while saving. It's just for slidedata value updates in UI
                "quarter": selectedquarter,
                "year": selectedYear,
                "highlight": "",
                "link": newsletter,
                "description": "",
                "title": "",
                "ai_flag": AIFlag
            })
        setKeyHighlightData([...updatedData])
        setAddRowCount(addRowCount + 1);
        if (newsletter !== '') {
            setNewsletterDisable(false)
        }
    }

    const delete_keyhighlight = async (row: any) => {
        try {
            if (row.id <= 0) {
                const updatedData = keyHighlightData.filter((data: any) => data.id !== row.id);
                setKeyHighlightData(updatedData);
                return;
            }
            else {
                setLoading(true);
                const res = await deleteKeyHighlight(authState?.accessToken?.accessToken, row.id);
                if (res?.data?.status === 'SUCCESS') {
                    if (AIFlag === 'NL') {
                        alert(`Key Highlight Deleted Successfully`)
                    } else {
                        alert(`AI Note Deleted Successfully`)
                    }
                    refreshTable();
                } else {
                    console.log(res);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };

    const updateInsert_keyhighlight = async () => {
        try {
            if ((newsletter === undefined || newsletter.trim().length === 0 || newsletter.indexOf('http') === -1) && AIFlag === "NL") {
                alert('Please enter valid Newsletter URL');
                return
            }
            setUpdateLoading(true);
            const payload = keyHighlightData.map((row: any, indx: number) => {  // replacing id with 0 for new row required for backend
                if (row.id <= 0) {
                    return {
                        "id": 0,
                        "quarter": row.quarter,
                        "year": row.year,
                        "highlights": row.highlight,
                        "link": newsletter,
                        "description": row.quarter + ' ' + row.year + ' Newsletter Highlights',
                        "title": row.quarter + ' ' + row.year + ' Newsletter',
                        "ai_flag": row.ai_flag,
                        "index": indx
                    }
                }
                else {   // for existing row and replacing key "highlight" with "highlights" for backend
                    return {
                        "id": row.id,
                        "quarter": row.quarter,
                        "year": row.year,
                        "highlights": row.highlight,
                        "link": row.link,
                        "description": row.quarter + ' ' + row.year + ' Newsletter Highlights',
                        "title": row.quarter + ' ' + row.year + ' Newsletter',
                        "ai_flag": row.ai_flag,
                        "index": indx
                    };
                }
            })

            const res = await updateKeyHighlight(authState?.accessToken?.accessToken, { "key_list": payload });
            if (res?.data?.status === 'SUCCESS') {
                if (AIFlag === 'NL') {
                    alert(`Key Highlight Updated Successfully`)
                } else {
                    alert(`AI Note Updated Successfully`)
                }
                refreshTable();
                KeyHighlightUpdateModalClosed();
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        setUpdateLoading(false)
    };

    const get_KeyHighlights = async (year: any) => {
        try {
            setLoading(true);
            const res = await getKeyHighlights(authState?.accessToken?.accessToken, "NL", parseInt(year));
            if (res?.data?.data?.content.length === 0) {
                setKeyHighlightData([]);
            }

            if (res?.data?.data?.content.length > 0) {
                const keyhighlights = res.data.data.content[0].newsLetters
                keyhighlights.sort((a: any, b: any) => a.index - b.index);
                setKeyHighlightData(keyhighlights);
                setKeyHighlightDataOriginal(keyhighlights);
                setQuarterList(getQtrAndyear(year));

            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };


    const get_KeyAINotes = async (year: any) => {
        try {
            setLoading(true);
            const res = await getKeyHighlights(authState?.accessToken?.accessToken, "AI", parseInt(year));
            if (res?.data?.data?.content.length > 0) {
                const keyAI = res.data.data.content[0].newsLetters
                keyAI.sort((a: any, b: any) => a.index - b.index);
                setKeyHighlightData(keyAI);
                setKeyHighlightDataOriginal(keyAI);
                setQuarterList(getQtrAndyear(year));
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };


    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const modalData = keyHighlightData.filter((content: any) => content.year === selectedYear && content.quarter === selectedquarter);
        const reorderedItem = modalData.splice(result.source.index, 1);
        modalData.splice(result.destination.index, 0, reorderedItem[0]);
        setKeyHighlightData(modalData)
    }

    const abortSave = () => {
        refreshTable();
        KeyHighlightUpdateModalClosed();
    }

    const getQtrAndyear = (year: string) => {
        const dt = new Date();
        const month = dt.getMonth() + 1;
        const currentYr = dt.getFullYear();
        let returnVal: string[] = [];

        if (currentYr !== parseInt(year)) {
            returnVal = ['Q1', 'Q2', 'Q3', 'Q4'];
            setSelectedquarter(returnVal[returnVal.length - 1]);
            return returnVal
        }
        //Below logic is to get the quarter based on the month, it opens up once that current quarter is over, since news letters are updated after that quarter is passed
        switch (true) {
            case month <= 3:
                //before april
                returnVal = ['Q1'];
                break;
            case month <= 6:
                //before july
                returnVal = ['Q1', 'Q2'];
                break;
            case month <= 9:
                //before october,
                returnVal = ['Q1', 'Q2', 'Q3'];
                break;
            case month <= 12:
                //before december,
                returnVal = ['Q1', 'Q2', 'Q3', 'Q4'];
                break;
        }

        // Settting returnVal.length - 2, since previous quarter newsletter is out in current quarter -1 + -1 
        if (returnVal.length >= 2) {
            setSelectedquarter(returnVal[returnVal.length - 2]);
            setSelectedYear(year);
        } else {
            //This will not be triggered, since previous year quarter will be selected in the above logic
            setSelectedquarter(returnVal[returnVal.length - 1]);
            setSelectedYear((parseInt(year) - 1).toString());
        }
        return returnVal;
    };

    const updateYearAndModalData = (year: any) => {
        setSelectedYear(year);
    }

    const updateQuarter = (qtrVal: any) => {
        setSelectedquarter(qtrVal);

        const newsletterData = keyHighlightDataOriginal.filter((content: any) => content.year === selectedYear && content.quarter === qtrVal)
        setKeyHighlightData(newsletterData);
        if (AIFlag === "NL") {
            setNewsletter(newsletterData?.[0]?.link?.toString())
            newsletterData.length > 0 ? setNewsletterDisable(true) : setNewsletterDisable(false);
        }
    }

    useEffect(() => {
        if (initialRender.current) {
            setQuarterList(getQtrAndyear(selectedYear));
            initialRender.current = false;
            return;
        }
        if (AIFlag === "NL") {
            get_KeyHighlights(selectedYear);
        } else {
            get_KeyAINotes(selectedYear);
        }
    }, [selectedYear, refreshTable])

    useEffect(() => {
        updateQuarter(selectedquarter);
    }, [selectedquarter])



    return (
        <Modal
            className="value-capture-slide-upload-modal"
            opened={opened}
            onClose={KeyHighlightUpdateModalClosed}
        >
            <Modal.Header contentClassName="d-flex align-items-center" >
                <Modal.Title title={title} className='value-capture-slide-modal-title' />
            </Modal.Header>
            <div className='value-capture-modal-content'>
                <div className="description-modal-body">
                    <div className='keyhighlight-dropdown'>
                        <div>
                            <label className='value-capture-label'>YEAR: </label>
                            <Select className='value-capture-select' value={selectedYear} onChange={(e) => updateYearAndModalData(e)}>
                                {yearList.map((year: any, index) =>
                                    <Select.Option key={index} value={year}>{year}</Select.Option>
                                )}
                            </Select>
                        </div>
                        <div>
                            <label className='value-capture-label'>QUARTER:</label>
                            <Select className='value-capture-select' value={selectedquarter} onChange={(e) => updateQuarter(e)}>
                                <>{quarterList.map((quarter: any, index) =>
                                    <Select.Option key={index} value={quarter}>{quarter}</Select.Option>
                                )}</>
                            </Select>
                        </div>
                    </div>
                    {AIFlag === "NL" &&
                        <div className='newsletter-link-box'>
                            <div className='value-capture-label'> <sup className="sup-star">*</sup> NEWSLETTER URL</div>
                            <TextArea
                                value={newsletter}
                                className='newsletter-text-box'
                                onChange={(e) => setNewsletter(e.target.value)}
                                disabled={newsletterDisable}
                            />
                        </div>
                    }
                    <div className={AIFlag === "NL" ? "key-highlight-modal-table-container" : "key-highlight-modal-table-container AI-Box"} >
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided: any) => (
                                    <table className="key-highlight-table" {...provided.droppableProps} ref={provided.innerRef} >
                                        <thead>
                                            <tr >
                                                {tableHeader.map((data: any, indx: any) => {
                                                    return (
                                                        <th key={indx} >
                                                            {data.Header}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {keyHighlightData.filter((content: any) => content.year === selectedYear && content.quarter === selectedquarter).map((data: any, indx: any) => {
                                                    return (
                                                        <Draggable key={'tr' + indx} draggableId={'tr' + indx} index={indx} >
                                                            {(provided: any) => (
                                                                <tr {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef} key={indx}>
                                                                    {tableHeader.map((colData: any, colIndx: any) => {
                                                                        if (colData.accessor === 'highlight') {
                                                                            return (
                                                                                <td key={indx + colIndx}>
                                                                                    <textarea
                                                                                        value={data[colData.accessor]}
                                                                                        autoFocus={false}
                                                                                        className='newsLetter-text-box'
                                                                                        name={colData.accessor}
                                                                                        onChange={e => update_DescriptionEvent(data, e.target.value)}
                                                                                    />
                                                                                </td>
                                                                            );
                                                                        } else if (colData.accessor === 'delete') {
                                                                            return (
                                                                                <td key={indx + colIndx}>
                                                                                    <Button type='primary' className='slide-delete-btn' text='' disabled={data.id === 0} onClick={() => delete_keyhighlight(data)} icon={<TrashIcon height={'20px'} width={'20px'} />} />
                                                                                </td>
                                                                            );
                                                                        }
                                                                    })}
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            </>
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>

                        </DragDropContext>
                    </div>
                    <Button type='secondary' text='ADD ITEM' onClick={addNew_keyHighlight} icon={<PlusIcon height={'18px'} width={'18px'} style={{ 'marginTop': '-2px' }} />} className='slide-add-btn'></Button>
                </div>
            </div>
            <div className='value-capture-modal-footer'>
                <div className='btn-container'>
                    <Button type='secondary' text='CANCEL' className='slide-btn' onClick={abortSave} ></Button>
                    <Button type='primary' text='SAVE CHANGES' className='slide-btn' onClick={updateInsert_keyhighlight} loading={updateLoading}></Button>
                </div>
            </div>
        </Modal >
    );
};

export default KeyHighlightUpdateModal;
