import React from 'react';
import './index.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const CarouselSlide = (props: Props) => {
  const { children, className, style } = props;
  return (
    <div className={`ops-carousel-slide ${className}`} style={style}>
      {children}
    </div>
  );
};

export default CarouselSlide;
