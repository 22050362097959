import React, {
  Children,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { LeftArrowIcon, RightArrowIcon } from 'icons';
import CarouselSlide from './carousel-slide';
import './index.scss';

type Props = {
  title?: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  stepSize?: number;
  total?: number;
  carouselOptions?: EmblaOptionsType;
};

const Carousel = (props: Props) => {
  const {
    title,
    children,
    className,
    stepSize = 1,
    total,
    carouselOptions = { loop: false, draggable: false },
  } = props;
  const [index, setIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel(carouselOptions);
  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      // console.log('scrollPrev');
      emblaApi.scrollPrev();
      setIndex(curr => {
        return curr - 1;
      });
    }
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      // console.log('scrollNext');
      emblaApi.scrollNext();
      setIndex(curr => {
        return curr + 1;
      });
    }
  }, [emblaApi]);

  const totalCount = useMemo(
    () => total || Children.count(children) * stepSize,
    [children, stepSize, total],
  );

  const startCount = useMemo(() => index * stepSize + 1, [index, stepSize]);
  const displayCount = useMemo(() => {
    const result = startCount + stepSize - 1;
    return result > totalCount ? totalCount : result;
  }, [startCount, stepSize, totalCount]);

  const classes = useMemo(
    () => ['ops-carousel-container', className ? className : ''].join(' '),
    [className],
  );

  useEffect(() => {
    if (emblaApi) {
      const { on, reInit } = emblaApi;
      on('resize', () => {
        if (window.innerWidth <= 768) {
          reInit();
        }
      });
    }
  }, [emblaApi]);

  return (
    <>
      <div className="ops-carousel-header d-flex w-100 mb-2">
        <div className="d-flex flex-grow-1">
          <div className={`about-carousel-nav-text ${title}`}>
            {`Showing ${startCount}`}
            <span className="d-md-inline d-none">{`-${displayCount}`}</span>
            {` of ${totalCount}`}
          </div>
        </div>
        <div className="d-flex flex-grow-0">
          {totalCount > stepSize && (
            <>
              <button
                className="ops-carousel-button"
                onClick={scrollPrev}
                disabled={index === 0}
              >
                <LeftArrowIcon
                  className="me-3 ops-pointer"
                  width={20}
                  height={20}
                />
              </button>
              <button
                className="ops-carousel-button"
                onClick={scrollNext}
                disabled={index === Children.count(children) - 1}
              >
                <RightArrowIcon
                  className="ops-pointer"
                  width={20}
                  height={20}
                />
              </button>
            </>
          )}
        </div>
      </div>
      <div className="ops-carousel" ref={emblaRef}>
        <div className={classes}>{children}</div>
      </div>
    </>
  );
};

Carousel.Slide = CarouselSlide;

export default Carousel;
