import React, { useEffect } from 'react';
import './index.scss';
import imageMap from '../../../assets/images';
import { ExternalLinkIcon } from '@gitlab-rtsensing/component-library';
import { NewsLetterList } from '../../../pages/about/about.d';

type List = {
  list: NewsLetterList[]
}

const NewsLetters = (props: List): JSX.Element => {

  const [previewList, setPreviewList] = React.useState(JSON.parse(JSON.stringify(props.list[0].highlight))); //Little convoluted to evade typeError
  const [newsletter, setNewsletter] = React.useState(props.list[0].description);
  useEffect(() => {
    document.querySelectorAll('.newsletter-item')[0]?.classList.add('selected');
  }, []);

  const onItemClicked = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {

    const highLightObj = e.currentTarget.getAttribute('data-highlight') || ''
    const description = e.currentTarget.querySelector('.newsletter-description')?.textContent || '';

    document.querySelectorAll('.newsletter-item').forEach((item) => {
      item.classList.remove('selected');
    })
    e.currentTarget.classList.add('selected');
    setPreviewList(highLightObj === '' ? [] : JSON.parse(highLightObj));
    setNewsletter(description);

    // console.log(e.currentTarget.querySelector('.newsletter-description')?.textContent)
  }

  return (

    <div className="newsletter-container">
      <div className="newsletter-preview">

        <div className="newsletter-preview-background" style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}/${imageMap['swayImage']})` }}></div>
        <div className="newsletter-preview-content">
          <>
            <p className="newsletter-highlight">{newsletter}</p>
            {previewList.map((obj: { text: string }, indx: string) => {
              return (
                <li key={indx}>{obj.text}</li>
              )
            })}
          </>
        </div>
      </div>

      <div className="newsletter-list">

        <ul>{props.list.map((obj: NewsLetterList, indx: any) => {
          return (
            <li className="newsletter-item" key={indx} onClick={(e) => onItemClicked(e)} data-highlight={JSON.stringify(obj.highlight)}>
              <div className="newsletter-item-title">
                <div>{obj.title}</div>
                <div className="newsletter-item-icon" onClick={() => window.open(obj.link, '_blank')}>
                  <ExternalLinkIcon
                    fill="var(--ops-primary-blue)"
                    height={18}
                    width={18}
                  />
                </div>
              </div>
              <div className="newsletter-description">{obj.description}</div>

            </li>
          )
        })}</ul>
      </div>
    </div>
  )
}

export default NewsLetters;
