import React from 'react';

import {
  ExpandArrowsIcon,
  Loader,
  MetricCard,
  OpsButton,
  OpsTypography,
} from '@gitlab-rtsensing/component-library';

import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import {
  Notes,
  Sublist,
  useReleaseNotesContext,
} from 'contexts/release-notes-context';

import { convertToTitleCase } from 'utils/string-utils';

import './index.scss';

export default function ReleaseNotes() {
  const { isLoading, latestNotes: latestReleaseNotes } =
    useReleaseNotesContext();
  const navigate = useNavigate();

  function renderReleaseNote(releaseNote: Sublist) {
    const keys = Object.keys(releaseNote.sublist.items);
    return keys.map((key, index) => {
      return (
        <li key={'li_' + index.toString()}>
          <div className="ops-dynamic-sublist-container">
            <div
              key={key + '_' + index.toString()}
              className="ops-dynamic-list-header"
            >
              {convertToTitleCase(key)}
            </div>
            <ul className="ops-dynamic-sublist">
              {releaseNote.sublist.items[key as keyof Notes]?.map(
                (subitem: string, index: number) => {
                  return (
                    <li
                      key={'sublist_li' + index.toString()}
                      className="ops-dynamic-sublist-item"
                    >
                      <p className="about-page-text">{subitem}</p>
                    </li>
                  );
                },
              )}
            </ul>
          </div>
        </li>
      );
    });
  }

  return (
    <div
      id="releasenotes"
      key="releasenotes"
      className="about-page-grid-item about-page-grid-item--releasenotes"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <MetricCard className="ops-metric-card">
          <>
            <MetricCard.Header className="tile-headers">
              <MetricCard.Title title="Release Notes" />
            </MetricCard.Header>
            <MetricCard.Content>
              <>
                {!latestReleaseNotes.sublist.version &&
                  !latestReleaseNotes.sublist.date ? (
                  <OpsTypography variant="p1" elementTag="div">
                    There are no pubished release notes
                  </OpsTypography>
                ) : (
                  <div>
                    <div className="about-tile-inner-grid">
                      <div
                        key="releasenotes"
                        className="about-tile-inner-grid-item single"
                      >
                        <>
                          <div className={`about-page-text `}>
                            {parse(
                              `All of Sensing's recent updates (released on ${latestReleaseNotes.sublist.date}) - from new features to enhancements`,
                            )}
                          </div>
                          <ul
                            key={'ul_unordered-list'}
                            style={{ height: 'auto' }}
                            className="ops-dynamic-list-bulleted"
                          >
                            {renderReleaseNote(latestReleaseNotes)}
                          </ul>
                        </>
                      </div>
                    </div>
                    <div className="release-notes-modal-button-container">
                      <OpsButton
                        className="release-notes-modal-button"
                        onClick={() => {
                          navigate('/about/notes');
                        }}
                        rightIcon={
                          <ExpandArrowsIcon
                            fill="var(--ops-primary-blue)"
                            height="14px"
                            width="14px"
                          />
                        }
                        type="secondary"
                        label="VIEW MORE"
                      />
                    </div>
                  </div>
                )}
              </>
            </MetricCard.Content>
          </>
        </MetricCard>
      )}
    </div>
  );
}
