import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@gitlab-rtsensing/component-library';

export type LinkWithIconProps = {
  className?: string;
  title?: string;
  link: {
    isExternal?: boolean;
    href: string;
  };
  tooltipText?: string;
};

export const LinkWithIcon = (props: LinkWithIconProps): JSX.Element => {
  const { className, title, link } = props;

  const containerClasses = classNames('ops-link-container', className);

  const linkClasses = classNames('ops-link-container-link', {
    'has-link': link?.href,
  });

  return (
    <div className={containerClasses}>
      <Link className={linkClasses} to={link.href}>
        <div className="ops-link-text">{title}</div>
        {link?.isExternal ? (
          <ExternalLinkIcon fill="#000" height={18} width={18} />
        ) : (
          <></>
        )}
      </Link>
    </div>
  );
};

export default LinkWithIcon;
